<template>
  <div class="separator">
    <div class="separator__item">&nbsp;</div>

    <!-- Debug -->
    <DebugItem v-if="debug.visible" :data="data"></DebugItem>
  </div>
</template>

<script>
import DebugItem from '@/components/DebugItem';

export default {
  name: 'ANZSEPARATOR',

  components: {
    DebugItem
  },

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    debug() {
      return this.$store.state.app.debug;
    }
  }
};
</script>

<style lang="scss">
.separator {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  position: relative;
}
</style>
